import React from 'react';
import { Icon } from '@iconify/react';
import Div from '../Div';
import './vertical-links.scss';

export default function VerticalLinks({ title, data, variant }) {
  return (
    <Div
      className={`cs-hero_social_wrap cs-primary_font cs-primary_color lg:mt-12 lg:text-center cs-social_btns cs-style1   ${
        variant ? variant : ''
      }`}
    >
      {title && <Div className="cs-hero_social_title  -right-3  ">{title}</Div>}
      {data && (
        <ul className="cs-hero_social_links text-center ">
          {data.map((item, index) => (
            // <li key={index}>
              <a key={index} href={item.links} style={{ transform: 'rotate(180deg)' }} className="cs-center transform rotate-90 hover:rotate-180    " target="_blank" rel="noopener noreferrer" >
                <Icon icon={item.icon} />
              </a>
            // </li>
          ))}
        </ul>
      )}
    </Div>
  );
}
