import React from 'react'
import { Link } from 'react-router-dom'

export default function MenuWidget({menuItems, menuHeading, variant}) {
  return (
    <div className='w-[100%]'>
      {menuHeading && <Link to={'/service'}><h2 className="cs-widget_title ">{menuHeading}</h2></Link>}
      <ul className={`${variant ? `cs-menu_widget ${variant}` : 'cs-menu_widget cs-style1'} cs-mp0`}>
        {menuItems.map((item, index) => (
          <li key={index} className={`${item.title === "WhatsApp Marketing" ? '-mt-11 md:mt-0' : 'mt-3'}`}>
            {item.href ? <Link to={item.href}>{item.title}</Link> : item.title}
          </li>
        ))}
      </ul>
    </div>
  )
}
