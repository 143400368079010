import React from 'react'
import Div from '../Div'

export default function TextWidget({logoSrc, logoAlt, text}) {
  return (
    <Div className="cs-text_widget flex flex-col justify-center items-center  md:block ">
      <img width={150} height={150} src={logoSrc} alt={logoAlt} />
      <p>{text}</p>
    </Div>
  )
}
