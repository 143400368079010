import React from 'react';
import Slider from 'react-slick';
import Div from '../Div';
import Post from '../Post';
const postData = [
  {
    src: '/images/blog/Des_7_11zon.png',
    title: 'Decoding the Impact of Social Media Analytics on Your Digital Strategy',
    alt:
    "blob",
    url: '/blog/1',
  },
  {
    src: '/images/blog/ssm_1_11zon.jpg',
    title: 'Unleashing the Power of Social Media Marketing',
    alt:
    "blob",
    url: '/blog/2',
  },

  {
    src: '/images/blog/instaBlog_11_11zon.png',
    title: 'Dominate the Digital World with Instagram Marketing',
    alt:
    "blob",
    url: '/blog/4',
  },
  {
    src: '/images/blog/D_fb_6_11zon.png',
    title: 'Dominate Your Online Presence with Effective Facebook Marketing Techniques',
    alt:"blog",
    url: '/blog/5',
  },
  {
    src: '/images/blog/content_calender_3_11zon.png',
  title: 'Effective Strategies for Crafting a Successful Content Calendar',
    alt: 'blog',
    url: '/blog/10',
  },

{
  src: '/images/blog/linkedin_12_11zon.png',
  title: 'Unleashing the Potential of LinkedIn for Your Marketing Strategy',
  alt:"blog",
  url: '/blog/7',
},
// {
//   src: '/images/comprehensive.png',
//   title: 'The Power of Social Media Advertising: A Comprehensive Guide',
//   alt:"blog",
//   url: '/blog/8',
// },
// {
//   src: '/images/newfrontier.png',
//   title: 'New Frontiers: Social Media Trends Redefining Digital Interactions',
//   alt:"blog",
//   url: '/blog/9',
// },
];
//

export default function PostSlider() {
  /** Slider Settings **/
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="cs-gap-24">
      {postData.map((item, index) => (
        <Div key={index}>
          <Post
            url={item.url}
            src={item.src}
            alt={item.alt}
            title={item.title}
          />
        </Div>
      ))}
    </Slider>
  );
}
