import React from 'react'
import { Icon } from '@iconify/react';

export default function ContactInfoWidget({withIcon, title}) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span>:''}
          <a href="tel:+919850015330">+91 98500 15330</a>
        </li>
        {/* <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:envelope" /></span>:''}
          <a href="mailto:digitalpjp@gmail.com">digitalpjp@gmail.com</a>
        </li> */}
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:envelope" /></span>:''}
          <a href="mailto:ping@pjpdigital.com">ping@pjpdigital.com</a>
        </li>
        
        <li>
  {withIcon ? <span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span> : ''}
  Suparshwnath Soc B, 573/29/3, Bibwewadi, Pune 411037.
</li>

      </ul>
    </>
  )
}
