import React from 'react';
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1 items-center flex justify-center md:block ">
      <a href="https://www.facebook.com/pjpdigital/" className="cs-center" target="_blank" rel="noopener noreferrer">
        <Icon icon="fa6-brands:facebook" />
      </a>
      <a href="https://x.com/pjpdigital?lang=en" className="cs-center" target="_blank" rel="noopener noreferrer">
        <Icon icon="fa6-brands:x-twitter" />
      </a>
      <a href="https://www.instagram.com/pjpdigital/" className="cs-center" target="_blank" rel="noopener noreferrer">
        <Icon icon="fa6-brands:instagram" />
      </a>
      <a href="https://in.linkedin.com/company/pjpdigital" className="cs-center" target="_blank" rel="noopener noreferrer">
        <Icon icon="fa6-brands:linkedin-in" />
      </a>
      
      <a href='https://www.youtube.com/@PJPDigital' className="cs-center" target="_blank" rel="noopener noreferrer">
        <Icon icon="fa6-brands:youtube" />
      </a>
      <a href="https://api.whatsapp.com/message/HYJBLAK2JINSI1?autoload=1&app_absent=0" className="cs-center" target="_blank" rel="noopener noreferrer">
        <Icon icon="fa6-brands:whatsapp" />
      </a>
      
    </Div>
  );
}
