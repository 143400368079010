import React from 'react'
import parse from 'html-react-parser';
import './cta.scss'
// import Button from '../Button';
import Div from '../Div';
import Spacing from '../Spacing';
import { Link } from 'react-router-dom';

export default function Cta({title, btnText, btnLink, bgSrc, variant}) {
  return (
    <Div className={`cs-cta cs-style1 cs-bg text-center cs-shape_wrap_1 cs-position_1 ${variant?variant:''}`} style={{backgroundImage: `url(${bgSrc})`}}>
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-cta_in">
        <h2 className="cs-cta_title cs-semi_bold cs-m0">{parse(title)}</h2>
        <Spacing lg='70' md='30' />
        <Div className="flex justify-center items-center">
              <div className="relative inline-block">
                <Link to={btnLink}>
                  <button className="bg-[#257cd9] relative px-3 py-2 rounded-md w-full text-white">
                    {btnText}
                  </button>
                </Link>
                <div className="bg-green-400 w-4 h-4 absolute -right-1 -top-1 delay-75  animate-ping rounded-full"></div>
              </div>
            </Div>
      </Div>
    </Div>
  )
}
