import { Icon } from '@iconify/react';
import React from 'react';

export default function Pagination({ currentPage, totalPages, paginate }) {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="cs-pagination_box cs-center cs-white_color cs-mp0 cs-semi_bold">
      {pageNumbers.map(number => (
        <li key={number}>
          <button
            onClick={() => paginate(number)}
            className={`cs-pagination_item cs-center ${currentPage === number ? 'active' : ''}`}
          >
            {number}
          </button>
        </li>
      ))}
      {/* Next Page Button */}
      <li>
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="cs-pagination_item cs-center"
        >
          <Icon icon="akar-icons:chevron-right" />
        </button>
      </li>
    </ul>
  );
}
