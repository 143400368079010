import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const ThankYouPage = () => {
  // Create a ref to keep track if the function has been called
  const hasCalledGtag = useRef(false);

  const gtag_report_conversion = () => {
    console.log("inside function ");
    
    // This is a mock `gtag` for local testing.
    if (!window.gtag) {
      console.log("inside if - no gtag in window");
      window.gtag = function () {
        console.log("Mock gtag call:", arguments);
      };
    }

    window.gtag("event", "conversion", {
      send_to: "AW-11376538882/OGpcCIH0jNoZEILq4LAq",
      value: 1.0,
      currency: "INR",
    });

    return false;
  };

  useEffect(() => {
    // Check if gtag_report_conversion has been called already
    if (!hasCalledGtag.current) {
      gtag_report_conversion();
      hasCalledGtag.current = true; // Mark as called
    }
  }, []);

  return (
    <div className="flex h-screen items-center justify-center px-4 sm:px-6 lg:px-8">
      <div>
        <div className="flex flex-col items-center space-y-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-24 w-24 text-green-600 sm:h-28 sm:w-28"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h1 className="text-3xl font-bold sm:text-4xl">Thank You!</h1>
          <p className="text-center text-sm sm:text-md">
            Thank you for your interest! We will get back to you soon.
          </p>

          <div className="relative inline-block">
            <Link to={"/"}>
              <button className="bg-[#257cd9] flex justify-center items-center relative px-4 py-2 rounded-md w-full text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16l-4-4m0 0l4-4m-4 4h18"
                  />
                </svg>
                {`Home`}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
