import React, { useState } from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';
const testimonialData = [
  {
    testimonialThumb: '/images/testimonial_1.jpeg',
    testimonialText: " Their comprehensive services, including social media marketing, performance marketing, online reputation management, and local SEO, have helped us achieve our online goals and grow our business. We are grateful for their expertise and professionalism.",
    avatarName: "Chakan's Lakdi Ghana Oil",
    // avatarDesignation: 'CEO AT TECH',
    ratings: '4.5',
  },
  {
    testimonialThumb: '/images/testimonial_1.jpeg',
    testimonialText:
      'Their experience on Instagram & Facebook has helped us reach a larger audience and improve interaction dramatically. We are happy with the results!',
    avatarName: "Zero Volt Therapy",
    // avatarDesignation: 'CEO AT TECH',
    ratings: '4',
  },
  {
    testimonialThumb: '/images/testimonial_1.jpeg',
    testimonialText:"Thanks to PJP Digital's local SEO services, our Google My Business listing now ranks higher, increasing visibility and foot traffic to our physical locations. Their local search optimization knowledge has made a difference for our business. Highly recommended!",
    avatarName: 'Trivikrama Oil',
    // avatarDesignation: 'CEO AT TECH',
    ratings: '4.5',
  },
  
  {
    testimonialThumb: '/images/testimonial_3.jpeg',
    testimonialText: " Maintaining a strong online reputation is crucial, and PJP Digital's online management services have been invaluable to us. They have helped us maintain our brand's credibility and successfully manage unfavourable criticism. We are grateful for their competence and dedication.",
    avatarName: 'Copian International',
    // avatarDesignation: 'CEO AT TECH',
    ratings: '4.5',
  },
  {
    testimonialThumb: '/images/testimonial_1.jpeg',
    testimonialText: "The Meta Ads and PPC solutions gave us a tailored reach, allowing us to interact with the right audience at the right time. Their Online Reputation Management services helped us retain a positive image, and we're grateful for how they handled everything with professionalism and care.",
    avatarName:"Thakkar's Tutorials",
    // avatarDesignation: 'CEO AT TECH',
    ratings: '4.5',
  },
  // {
  //   testimonialThumb: '/images/testimonial_2.jpeg',
  //   testimonialText: "Performance marketing is vital in today's digital marketplace, and PJP Digital has exceeded our expectations in this area. Their techniques have created outstanding online conversions and enhanced our ROI. We suggest their services to businesses trying to enhance their web exposure.",
  //   // avatarName: 'Ahon Monsery',
  //   // avatarDesignation: 'CEO AT TECH',
  //   ratings: '5',
  // },
  
  
  // {
  //   testimonialThumb: '/images/testimonial_1.jpeg',
  //   testimonialText: "Their social Media Marketing techniques improved our online presence by increasing engagement and creating a loyal community around our business. The Performance Marketing campaigns they created were remarkable, with demonstrable outcomes far beyond our expectations.",
  //   // avatarName: 'Ahon Monsery',
  //   // avatarDesignation: 'CEO AT TECH',
  //   ratings: '4.5',
  // },
  
];

export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={slider2 => setNav2(slider2)}
                slidesToShow={1}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {/* {testimonialData.map((index) => (
                  <Div className="slider-nav_item" key={index}> */}
                    {/* <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in"> */}
                        {/* <img src={item.testimonialThumb} alt="Thumb" /> */}
                      {/* </Div>
                    </Div> */}
                  {/* </Div>
                ))} */}
              </Slider>
            </Div>
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={slider1 => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.testimonialText}
                      avatarName={item.avatarName}
                      avatarDesignation={item.avatarDesignation}
                      ratings={item.ratings}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}
