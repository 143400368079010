import React from 'react';
import { useState } from 'react';
import Div from '../Div';

const accordionData = [
  {
    question: 'What is PJP Digital?',
    answer:
    'PJP Digital is a digital marketing agency based in Pune specializing in empowering brands through tailored digital marketing solutions.'
  },
  {
    question: '   What sets PJP Digital apart from other agencies? ',
    answer:
'Commitment to quality and passion for client success is central to ensuring that each digital strategy is tailored to individual results.'  },
{
  "question": "How can social media marketing benefit my business?",
  "answer": "Social media marketing enables direct customer engagement, enhances brand visibility, and allows for targeted advertising, fostering better relationships with potential clients."
},
{
  "question": "What types of businesses can benefit from digital marketing agencies?",
  "answer": "Digital marketing strategies can significantly benefit businesses of all sizes and across various industries—especially e-commerce, service-based, and tech companies."
},
{
  "question": "How long does it take to see results from digital marketing?",
  "answer": "Results can vary depending on the strategy implemented, but typically, you may start seeing noticeable changes within a few months."
},
{
  "question": "What is the cost of digital marketing services?",
  "answer": "Costs can vary based on the scope of services required and the specific digital marketing strategies employed. Discussing a tailored plan can provide clarity."
},
{
  "question": "What kind of support do you offer after project completion?",
  "answer": "Continuous support and consultation are provided to ensure sustained success and ongoing optimization of digital strategies."
},
{
  "question": "Can small businesses afford digital marketing?",
  "answer": "Yes, digital marketing can be tailored to fit various budgets, and many small businesses find that even a small investment can yield significant returns."
},
{
  "question": "What is your approach to branding?",
  "answer": "We take a tailored, holistic approach to developing a unique brand identity that aligns with each client's vision and goals."
},
{
  "question": "Do you offer any guarantees for results?",
  "answer": "While results depend on various factors, strategies are designed to maximize potential outcomes and conversions."
}
];

export default function Accordion() {
  const [selected, setSelected] = useState(0);
  const handelToggle = index => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index) => (
        <Div
          className={`cs-accordian ${selected === index ? 'active' : ''} border-r-0 border-t-0 border-l-0 outline-none `}
          key={index}
        >
          <Div
            className="cs-accordian_head"
            onClick={() => handelToggle(index)}
          >
            <h2 className="cs-accordian_title">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg
                width={15}
                height={8}
                viewBox="0 0 15 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>
            </span>
          </Div>
          <Div className="cs-accordian_body">
            <Div className="cs-accordian_body_in">{item.answer}</Div>
          </Div>
        </Div>
      ))}
    </Div>
  );
}
