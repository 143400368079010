import React, { useState } from 'react'
import Div from '../Div'
import emailjs from "emailjs-com"; // Import emailjs


export default function Newsletter({title, subtitle, placeholder}) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [errorMessage, setErrorMessage] = useState(""); // Error message state
  const [successMessage, setSuccessMessage] = useState(""); // Success message state
  const [errors, setErrors] = useState({}); // Error state for validation

    // Handle input changes and remove error dynamically
    const handleChange = (e) => {
      const { value } = e.target;
      setEmail(value);
  
      // Remove error if field becomes valid
      if (value) {
        setErrors({
          ...errors,
          email: "",
        });
      }
    };
  
    // Validate email
    const validate = () => {
      const newErrors = {};
  
      // Basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        newErrors.email = "Please enter a valid email address";
      }
  
      setErrors(newErrors);
  
      // Return true if no errors
      return Object.keys(newErrors).length === 0;
    };
  
    // Function to send email via EmailJS
    const sendEmail = () => {
      setLoading(true); // Start loading
  
      const emailData = {
        email_address: email, // Send the email entered by the user
        to_name: email, // Recipient name
        message: `New newsletter subscription from: ${email}`,
      };
      // console.log("imcomming data ",emailData)
      emailjs
        // .send(
        //   "service_38r8qbr", // Replace with your EmailJS service ID
        //   "template_x7n40nv", // Replace with your EmailJS template ID
        //   emailData,
        //   "4pelo1DoRbwjQMTQs" // Replace with your EmailJS public key
        // )
        .send(
          "service_nnmhovp",
          "template_u8xsy7u",
          emailData,
          "_3ewje7MNydvt-Qo8"
        )
        .then(
          (response) => {
            setLoading(false); // Stop loading
            setSuccessMessage("Email successfully sent! Thank you for subscribing.");
            setEmail(""); // Clear the email input field
          },
          (error) => {
            setLoading(false); // Stop loading
            setErrorMessage("Failed to send the email. Please try again.");
          }
        );
    };
  
    // Form submission handler
    const handleSubmit = (e) => {
      e.preventDefault();
      setErrorMessage(""); // Clear previous error
      setSuccessMessage(""); // Clear previous success
  
      if (validate()) {
        sendEmail();
      } else {
        setErrorMessage("Please correct the highlighted fields");
      }
    };
  return (
    <>
      {title && <h2 className="cs-widget_title text-center">{title}</h2>}
      <Div className="cs-newsletter cs-style1">
        <form onSubmit={handleSubmit} className="cs-newsletter_form">
          <input
            type="email"
            className={`cs-newsletter_input ${
              errors.email ? "error-border" : ""
            }`}
            placeholder={placeholder}
            value={email}
            onChange={handleChange}
          />
          <button
            className="cs-newsletter_btn"
            type="submit"
            disabled={loading}
          >
            <span>{loading ? "Sending..." : "Send"}</span>
          </button>
        </form>

        {/* Display error message */}
        {errorMessage && <Div className="error-text text-center">{errorMessage}</Div>}

        {/* Display success message */}
        {successMessage && <Div className="success-text text-center">{successMessage}</Div>}

        <Div className="cs-newsletter_text text-center">{subtitle}</Div>
      </Div>
    </>
  )
}
