import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Card from "../Card";
import Cta from "../Cta";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import TestimonialSlider from "../Slider/TestimonialSlider";
import Spacing from "../Spacing";

const services = [
  {
    title: "Digital Marketing",
    link: "/service/digital-marketing",
    src: "images/services/Digital_marketing_3_11zon.webp",
    alt: "Service",
  },
  {
    title: "Social Media Marketing",
    link: "/service/social-media-marketing",
    src: "images/services/Social_Media_Marketing_11_11zon.webp",
    alt: "Social Media Marketing",
  },
  {
    title: "Performance Marketing",
    link: "/service/performance-marketing",
    src: "images/services/Performance_Marketing_9_11zon.webp",
    alt: "Performance Marketing",
  },
  {
    title: "Content Marketing",
    link: "/service/content-marketing",
    src: "images/services/Content_marketing_1_11zon.webp",
    alt: "Service",
  },
  
  {
    title: "Meta Ads",
    link: "/service/meta-ads",
    src: "images/services/Meta_Ads_6_11zon.webp",
    alt: "Meta Ads",
  },
  {
    title: "Google Ads",
    link: "/service/google-ads",
    src: "images/services/google_ads2_4_11zon.webp",
    alt: "Service",
  },
  {
    title: "WhatsApp Marketing",
    link: "/service/whatsapp-marketing",
    src: "images/services/whatsapp_marketing.webp",
    alt: "Service",
  },
  {
    title: "Online Reputation Management",
    link: "/service/online-reputation-management",
    src: "images/services/ORM_7_11zon.webp",
    alt: "Social Media Marketing",
  },
  { title: "Search Engine Optimization", link: "/service/SEO", src: "images/services/SEO_10_11zon.webp", alt: "SEO" },  
  {
    title: "Google My Business",
    link: "/service/google-my-business",
    src: "images/services/Google-my-business_5_11zon.webp",
    alt: "Performance Marketing",
  },
  {
    title: "Content Writing",
    link: "/service/content-writing",
    src: "images/services/Content_Writing_2_11zon.webp",
    alt: "Service",
  },
  
  {
    title: "Web Design & Development",
    link: "/service/web-design-and-development",
    src: "images/services/web_design_and_development_12_11zon.webp",
    alt: "Service",
  },
  // {
  //   title: "Pay Per Click (PPC)",
  //   link: "/service/pay-per-click",
  //   src: "images/services/Pay_per_click.webp",
  //   alt: "Service",
  // },
  
  
];

export default function ServicesPage() {
  pageTitle("Service");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <PageHeading 
        title='Services'
        bgSrc='images/service_hero_bg.jpeg'
        pageLinkText='Services'
      /> */}
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4 mb-9 ">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="We offer a variety of services to assist you"
                subtitle="What can we provide?"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                {services
                // .sort((a, b) => a.title.localeCompare(b.title))
                .map((service, index) => (
                  <Div
                    key={index}
                    className={`col-lg-3 col-sm-6 mb-9 $`}
                  >
                    <Card
                      title={service.title}
                      link={service.link}
                      src={service.src}
                      alt={service.alt}
                    />
                    {/* <Spacing lg="0" md="30" /> */}
                  </Div>
                ))}
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* <Spacing lg="150" md="80" /> */}
      <TestimonialSlider />
      {/* <Spacing lg="150" md="80" /> */}
      <Div className="container mt-9">
        <Cta
          title="Let’s discuss and make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
