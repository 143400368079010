import React, { useEffect } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import PortfolioSlider from '../Slider/PortfolioSlider';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import VideoModal from '../VideoModal';
import { pageTitle } from '../../helper';
import LogoInfiniteSlider from '../Slider/LogoInfiniteSlider';

const funfaceData = [
  {
    title: 'Happy Clients',
    factNumber: '50',
  },
  // {
  //   title: 'Project Completed',
  //   factNumber: '50K',
  // },
  {
    title: 'Team Members',
    factNumber: '20',
  },
  // {
  //   title: 'Digital products',
  //   factNumber: '550',
  // },
];

const portfolioData = [
  {
    title: "Chakan's Lakdi Ghana Oil",
    subtitle: 'See Details',
    href: '/portfolio/chakan-lakadi-oil',
    src: "/images/portfolio/chakans lakdi ghana oil_1_11zon (1).webp",
    category: 'e_com',
  },
  {
    title: "Trivikrama Oil",
    subtitle: 'See Details',
    href: '/portfolio/trivikarama-oil',
    src: "/images/portfolio/Trivikrama Oil_6_11zon (1).webp",
    category: 'e_com',
  },
  {
    title: "Thakkar's Tutorials",
    subtitle: 'See Details',
    href: '/portfolio/takkars-tutorials',
    src: "/images/portfolio/Thakkar tutorials_5_11zon (1).webp",
    category: 'ed_tech',
  },
  {
    title: 'Copian International',
    subtitle: 'See Details',
    href: '/portfolio/copian-international',
    src: '/images/portfolio/Copian_2_11zon (1).webp',
    category: 'b2b_tech',
  },
  {
    title: 'Gala Decor',
    subtitle: 'See Details',
    href: '/portfolio/gala-decor',
    src: '/images/portfolio/gala_decor_4_11zon (1).webp',
    category: 'home_living',
  },
  {
    title: "Designer's Element",
    subtitle: 'See Details',
    href: '/portfolio/design-elements',
    src: "/images/portfolio/designers elements_3_11zon (1).webp",
    category: 'home_living',
  },
  {
    title: 'Zero 2 Commune - Props Store',
    subtitle: 'See Details',
    href: '/portfolio/Z2C-prop-store',
    src: '/images/portfolio/z2c_7_11zon(1).webp',
    category: 'e_com',
  },
  {
    title: 'Zero Volt Therapy',
    subtitle: 'See Details',
    href: '/portfolio/zero-volt-therapy',
    src: '/images/portfolio/ZVT_8_11zon(1).webp',
    category: 'health_tech',
  },
  
];

const socialLinks = [
  { name: 'Facebook', icon: 'fa6-brands:facebook', links: 'https://www.facebook.com/pjpdigital/' },
  { name: 'Twitter', icon: 'fa6-brands:x-twitter', links: 'https://x.com/pjpdigital?lang=en' },
  { name: 'Instagram', icon: 'fa6-brands:instagram', links: 'https://www.instagram.com/pjpdigital/' },
  { name: 'LinkedIn', icon: 'fa6-brands:linkedin-in', links: 'https://in.linkedin.com/company/pjpdigital' },
  { name: 'YouTube', icon: 'fa6-brands:youtube', links: 'https://www.youtube.com/@PJPDigital' },
  // { name: 'WhatsApp', icon: 'fa6-brands:whatsapp', links: 'https://api.whatsapp.com/message/HYJBLAK2JINSI1?autoload=1&app_absent=0' },
];
export default function Home() {
  pageTitle('Home');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="PJP DIGITAL - YOUR VOICE IN THE DIGITAL SPACE "
        // subtitle="Tailored Digital Marketing Strategies That Drive Real Results"
        // subtitle="Tailored Digital Marketing Strategies That Drive Real Results"
        btnText="Get a Quote"
        btnLink="/contact"
        scrollDownId="#service"
        socialLinksHeading="Follow Us"
        heroSocialLinks={socialLinks}
        bgImageUrl="/images/bg.webp"
      />
    
      <div className="container">
        <FunFact
          variant="cs-type1"
          title="Interesting Tidbit About us"
          subtitle="We are committed to helping your business stand out in the digital world so you can get the recognition and success you need in this cutthroat industry. Whether you're a new firm trying to build your name or an old one trying to improve your online strategy, we're here to assist you."
          data={funfaceData}
        />
      </div>
      
      <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                // title="Services we can help you with"
                title="We offer a variety of services to assist you"
                subtitle="What can we provide? "
                btnText="See All Services"
                btnLink="/service"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Social Media Marketing"
                    link="/service/social-media-marketing"
                    src="/images/services/Social_Media_Marketing_11_11zon.webp"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Meta Ads"
                    link="/service/meta-ads"
                    src="/images/services/Meta_Ads_6_11zon.webp"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Digital Marketing"
                    link="/service/digital-marketing"
                    src="/images/services/Digital_marketing_3_11zon.webp"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Performance Marketing"
                    link="/service/performance-marketing"
                    src="/images/services/Performance_Marketing_9_11zon.webp"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    
      <Spacing lg="150" md="50" />
      <Div>
        <Div className="container">
          <SectionHeading
            title="Portfolio to explore"
            subtitle="Latest Projects"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider data={portfolioData} />
      </Div>
      {/* End Portfolio Section */}

  

      {/* Start Video Block Section */}
      <Spacing lg="130" md="70" />
      <Div className="container">
        <h3 className="cs-font_40 cs-m0 text-center cs-line_height_4 ">
        Our goal here at PJP Digital is to change how companies of all sizes use digital marketing. We are more than a service provider; we are a reliable ally in your pursuit of success in the ever-changing digital world.
        </h3>
        {/* <Spacing lg="70" md="70" sm="0" className="sm:mt-0"/> */}
        <VideoModal
          videoSrc="https://www.youtube.com/watch?v=2sl-bAqlJ_o"
          bgUrl="/images/pjpthumbnail.png"
        />
      </Div>
    
      <TestimonialSlider />

      {/* <Spacing lg="150" md="80" /> */}
      <Div className="cs-shape_wrap_4 sm:mt-3 mt-9">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Check out the recent publications"
                subtitle="Our Blog"
                btnText="View More Blog"
                btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    
      
      <Div className="container w-full">
        
      <Spacing lg="125" md="70" />

        <LogoInfiniteSlider/>
      </Div>
      
      <Div className="container mt-9 md:mt-9">
        <Cta
          title="Together, let's Create, <br> Connect and build."
          btnText="Fix your meeting with us"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
  
      
      {/* End CTA Section */}
      
    </>
  );
}
