// import React from 'react'
// import { Link } from 'react-router-dom'
// import Button from '../Button'
// import Div from '../Div'
// import './post.scss'

// export default function PostStyle2({thumb, title, subtitle, date, category, categoryHref, href}) {
//   return (
//     <Div className="cs-post cs-style2 mt-4 ">
//       <Link to={href} className="cs-post_thumb cs-radius_15">
//         <img src={thumb} alt="Post" className="w-100 " />
//       </Link>
//       <Div className="cs-post_info">
//         <Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
//           {/* <span className="cs-posted_by">{date}</span> */}
//           {/* <Link to={categoryHref} className="cs-post_avatar">{category}</Link> */}
//         </Div>
//         <h2 className="cs-post_title"><Link to={href}>{title}</Link></h2>
//         <Div className="cs-post_sub_title">{subtitle}</Div>
//         <Button btnLink={href} btnText='See More' />
//       </Div>
//     </Div>
//   )
// }

import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import Div from '../Div';

export default function PostStyle2({ thumb, title, subtitle, date, category, categoryHref, href }) {
  const truncateTitle = (str, maxLength) => {
    return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
  };
  return (
    <Div className="mt-4 shadow-lg rounded-lg overflow-hidden">
      <Link to={href} className="block">
        <img src={thumb} alt="Post" className="w-full h-56 object-cover" />
      </Link>
      <Div className="py-3 px-2 ">
        <Div className="text-gray-500 mb-2 text-sm">
          {/* <span className="mr-2">{date}</span> */}
          {/* <Link to={categoryHref} className="text-indigo-600">{category}</Link> */}
        </Div>
        <h2 className="text-xl font-semibold mb-3">
          <Link to={href} className="text-white hover:text-indigo-600">{title}</Link>
        </h2>
        <p className="text-white mb-4 mt-2 font-light">{truncateTitle(subtitle, 100)}</p>
        <Button btnLink={href} btnText="See More" />
      </Div>
    </Div>
  );
}
