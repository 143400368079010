import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../helper";
import Accordion from "../Accordion";
import Button from "../Button";
import Cta from "../Cta";
import IconBox from "../IconBox";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import TestimonialSlider from "../Slider/TestimonialSlider";
import Spacing from "../Spacing";
import { serviceData } from "../../data";

export default function ServiceDetailsPage() {


  pageTitle("Service Details");
  const { serviceId } = useParams();
  const [service, setService] = useState(serviceData[serviceId] || {});

  useEffect(() => {
    window.scrollTo(0, 0);
    setService(serviceData[serviceId]);
  }, [ serviceId]);
  const serviceIds = Object.keys(serviceData).filter((id) => id !== serviceId);

  return (
    <>
      <Spacing lg="145" md="80" />

      <Div className="container">
        <SectionHeading
          title={service.title}
          // subtitle={service.subtitle}
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          {service.steps?.map((step, index) => (
            <Div className="col-lg-4" key={index}>
              <IconBox
                icon={step.icon}
                title={step.title}
                subtitle={step.subtitle}
              />
              <Spacing lg="30" md="30" />
            </Div>
          ))}
        </Div>
      </Div>
      <Spacing lg="120" md="50" />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img
                src={service?.src}
                alt="Service"
                className="cs-radius_15 w-100"
              />
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0"> Explore Our related services</h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              {serviceIds
                .sort((a, b) => serviceData[a].subtitle.localeCompare(serviceData[b].subtitle))
                .slice(0, Math.ceil(serviceIds.length / 2))
                .map((id, index) => (
                  <Div className="col-lg-6" key={index}>
                    <Button
                      btnLink={`/service/${id}`}
                      btnText={serviceData[id].subtitle}
                      variant="cs-type2"
                    />
                    <Spacing lg="20" md="10" />
                  </Div>
                ))}
              {serviceIds
                .slice(Math.ceil(serviceIds.length / 2))
                .map((id, index) => (
                  <Div
                    className="col-lg-6"
                    key={index + Math.ceil(serviceIds.length / 2)}
                  >
                    <Button
                      btnLink={`/service/${id}`}
                      btnText={serviceData[id].subtitle}
                      variant="cs-type2"
                    />
                    <Spacing lg="20" md="10" />
                  </Div>
                ))}
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <TestimonialSlider />
      <Spacing lg="145" md="80" />
      <Div className="container cs-shape_wrap_4">
        {/* <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div> */}
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title="Some pre questions and answers"
                subtitle="FAQ’s"
                src={"/images/FAQ21.png"}
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
