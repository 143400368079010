import React from "react";
import Div from "../Div";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import Newsletter from "../Widget/Newsletter";
import SocialWidget from "../Widget/SocialWidget";
import TextWidget from "../Widget/TextWidget";
import "./footer.scss";

const serviceMenuColumn1 = [
  {
    title: "Digital Marketing",
    href: "/service/digital-marketing",
  },
  {
    title: "Social Media Marketing",
    href: "/service/social-media-marketing",
  },
  {
    title: "Performance Marketing",
    href: "/service/performance-marketing",
  },
  {
    title: "Content Marketing",
    href: "/service/content-marketing",
  },
  {
    title: "Meta Ads",
    href: "/service/meta-ads",
  },
  {
    title: "Google Ads",
    href: "/service/google-ads",
  },
];


const serviceMenuColumn2 = [
  {
    title: "WhatsApp Marketing",
    href: "/service/whatsapp-marketing",
  },
  {
    title: "Online Reputation Management",
    href: "/service/online-reputation-management",
  },
  {
    title: "Search Engine Optimization",
    href: "/service/SEO",
  },
  {
    title: "Google My Business",
    href: "/service/google-by-business",
  },
  {
    title: "Content Writing",
    href: "/service/content-writing",
  },
  {
    title: "Web Design and Development",
    href: "/service/web-design-and-development",
  },
];

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container mx-auto px-4">
          <Div className="row ">
            <Div className="col-lg-3 col-sm-8 ">
              <Div className="cs-footer_item ">
                <TextWidget
                  logoSrc="/images/PJPLogo_with_name.png"
                  logoAlt="Logo"
                  text="Start Your Journey to Digital Success Now Begin is a collaboration focused on achieving measurable results and growth for your brand.
                  Whether you're looking to boost your social media presence, drive performance marketing results, manage your online reputation, or improve your local SEO, we have you covered."
                />
                <SocialWidget />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item flex flex-col  items-center  md:block ">
                {/* First service column */}
                <MenuWidget
                  menuItems={serviceMenuColumn1}
                  menuHeading="Services"
                />
              </Div>
            </Div>

            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item flex flex-col justify-center items-center  md:block ">
                {/* Second service column, with invisible heading */}
                <MenuWidget
                  menuItems={serviceMenuColumn2}
                  menuHeading={<span className="hidden md:block md:text-transparent ">Services</span>}
                />
              </Div>
            </Div>

            <Div className="col-lg-3 col-sm-8">
              <Div className="cs-footer_item  ">
                <ContactInfoWidget title="Contact Us" />
              </Div>
              {/* <Div className="cs-footer_item">
                <Newsletter
                  title="Subscribe Now"
                  subtitle="Experience the PJP Difference — Reach Out Now Connect today to explore a partnership dedicated to your brand's success."
                  placeholder="example@gmail.com"
                />
              </Div> */}
            </Div>
          </Div>
          <div className="flex justify-center items-center">
          <Div className="col-lg-5 col-sm-8 flex justify-center items-center">
            <Div className="cs-footer_item">
              <Newsletter
                title="Subscribe Now"
                subtitle="Experience the PJP Difference — Reach Out Now Connect today to explore a partnership dedicated to your brand's success."
                placeholder="example@gmail.com"
              />
            </Div>
          </Div>
          </div>
          
        </Div>
      </Div>

      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">
              Copyright © {currentYear} PJP Digital
            </Div>
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
