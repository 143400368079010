import React from 'react';

const LOGOS = [
  {
    src: '/images/clients/clg_oil.jpeg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/client_logo_2.jpg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/client_logo_3.jpg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/client_logo_4.jpg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/client_logo_5.jpg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/client_logo_6.jpg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/client_logo_7.jpg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/client_logo_8.jpg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/client_logo_9.jpg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/designer.jpeg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/Untitled_design.jpg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/iskon.jpeg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/roborbit.jpeg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/savein.jpeg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/thakkars.jpeg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/trivi.jpeg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/yashwant.jpeg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/zero2c.jpeg',
    alt: 'Partner',
  },
  {
    src: '/images/clients/zvt.jpeg',
    alt: 'Partner',
  },

];

const LogoInfiniteSlider = () => {
  return (
    <>
      <h1 className="text-center mb-5 z-50 mt-6 text-white">
        Our Reputable Clients
      </h1>
      <div className="relative m-auto w-full overflow-hidden bg-black before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,black_0%,rgba(0,0,0,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,black_0%,rgba(0,0,0,0)_100%)] after:content-['']">
        <div className="animate-infinite-slider flex w-[calc(300px*40)] gap-x-12">
          {LOGOS.map((logo, index) => (
            <div
              className="slide flex w-[300px] items-center justify-center"
              key={index}
            >
              <img
                src={logo.src}
                alt={logo.alt}
                className="h-50 w-auto object-contain scale-125"
                loading="lazy"
              />
            </div>
          ))}
          {/* Duplicate for smooth infinite loop */}
          {LOGOS.map((logo, index) => (
            <div
              className="slide flex w-[300px] items-center justify-center"
              key={index + LOGOS.length}
            >
              <img
                src={logo.src}
                alt={logo.alt}
                className="h-50 w-auto object-contain scale-125"
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default LogoInfiniteSlider;
