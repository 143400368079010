import { Route, Routes } from 'react-router-dom';
import AboutPage from './components/Pages/AboutPage';
import BlogPage from './components/Pages/BlogPage';
import BlogDetailsPage from './components/Pages/BlogDetailsPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import PortfolioDetailsPage from './components/Pages/PortfolioDetailsPage';
import ServiceDetailsPage from './components/Pages/ServiceDetailsPage';
import ServicesPage from './components/Pages/ServicesPage';
import PortfolioPage from './components/Pages/PortfolioPage';
import Layout from './components/Layout';
import FaqPage from './components/Pages/FaqPage';
import { Icon } from '@iconify/react/dist/iconify.js';
import ThankYouPage from './components/Pages/ThankYouPage';



function App() {
  return (
    <>
    <div>
      <a
        href="	https://api.whatsapp.com/message/HYJBLAK2JINSI1?autoload=1&app_absent=0" // Dynamically setting the link
        className="float flex justify-center items-center text-center mx-auto my-auto "
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon icon="fa6-brands:whatsapp" className="my-float mx-auto my-auto" style={{ fontSize: '34px',textAlign:"center"  }} />
      </a>
    </div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="service" element={<ServicesPage />} />
          <Route
            path="service/:serviceId"
            element={<ServiceDetailsPage />}
          />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route
            path="portfolio/:portfolioDetailsId"
            element={<PortfolioDetailsPage />}
          />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} />
          <Route path="contact" element={<ContactPage />} />
          
          <Route path="faq" element={<FaqPage />} />
          <Route path="thank-you" element={<ThankYouPage />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
