import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cta from "../Cta";
import Div from "../Div";
import Spacing from "../Spacing";
import { blogData } from "../../data";
import { customStyles, services } from '../../data';
import ReactSelect from 'react-select';
import emailjs from "emailjs-com";


export default function BlogDetailsPage() {
  const { blogDetailsId } = useParams();
  const [blog] = useState(blogData[blogDetailsId] || {});
  const [loading, setLoading] = useState(false); // Loading state
  const [errorMessage, setErrorMessage] = useState(""); // Error message state
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [formData, setFormData] = useState({
    fullName: "",
    mobile: "",
    selectedServices: [],
  });
  const sendEmail = () => {
    setLoading(true); // Start loading
    const emailData = {
      from_name: formData.fullName,
      mobile: formData.mobile,
      to_name: "PJP Digital", // Adjust this based on your recipient
      message: `${formData.selectedServices
        .map((service) => service.label)
        .join(", ")}.`,
    };
    emailjs
      .send(
        "service_nnmhovp",
        "template_kpluxyf",
        emailData,
        "_3ewje7MNydvt-Qo8"
      )
      .then(
        (response) => {
          setFormData({
            fullName: "",
            mobile: "",
            selectedServices: [],
          });
          setErrors({}); // Clear errors if any
          setLoading(false); // Stop loading
          navigate("/thank-you");
        },
        (error) => {
          setErrorMessage("Failed to send the email. Please try again."); // Set error message
          setLoading(false); // Stop loading
          setErrors({}); // Optionally handle errors if necessary
        }
      );
  };


  // Handling input changes and removing error messages dynamically
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Remove error message if field is valid
    if (value) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  // Handling service change for multi-select and removing error messages dynamically
  const handleServiceChange = (selectedOptions) => {
    setFormData({
      ...formData,
      selectedServices: selectedOptions || [],
    });

    // Remove error message if at least one service is selected
    if (selectedOptions.length > 0) {
      setErrors({
        ...errors,
        selectedServices: "",
      });
    }
  };

  // Validate fields
  const validate = () => {
    const newErrors = {};
    if (!formData.fullName) newErrors.fullName = "Full Name is required";
    if (!formData.selectedServices.length)
      newErrors.selectedServices = "Please select at least one service";
    if (!/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = "Please enter a valid 10-digit mobile number";
    }

    setErrors(newErrors);

    // Returns true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  // Form submission handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      sendEmail();
    } else {
      setErrorMessage("Validation failed")
      console.log("Validation failed");
    }
  };
  return (
    <>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-12">
            {/* Start Details Post Content */}
            <Div className="cs-post cs-style2">
              <Div className="cs-post_thumb cs-radius_15">
                <img
                  src={blog.image}
                  alt="Post"
                  className="w-100 cs-radius_15"
                  loading="lazy"
                />
              </Div>
              <Div className="cs-post_info">
                <h2 className="cs-post_title">{blog.title}</h2>
                <p>{blog.content}</p>
                <p dangerouslySetInnerHTML={{ __html: blog.content2 }} />

                {blog.quote && (
                    <blockquote className="cs-primary_font border-t-0 border-r-0 border-b-0">
                    {blog.quote}
                    {blog.writer ? <small>{blog.writer}</small> : ""}
                  </blockquote>
                ) }

                <p dangerouslySetInnerHTML={{ __html: blog.content3 }} />
              </Div>
            </Div>

            {/* Start Comment Section */}
            <Spacing lg="30" md="30" />
            <h2 className="cs-font_50 cs-m0">Leave A Reply</h2>
            <Spacing lg="5" md="5" />
            <p className="cs-m0">
              Your email address will not be published. Required fields are
              marked *
            </p>
            <Spacing lg="40" md="30" />
            <Div className="container">
            <Div className="col-lg-12">
            <form onSubmit={handleSubmit} className="row">
              <Div className="col-sm-12">
                <label className="cs-primary_color">
                  Full Name <span className="background-red">*</span>
                </label>
                <input
                  type="text"
                  name="fullName"
                  placeholder="John Doe"
                  className={`cs-form_field ${
                    errors.fullName ? "error-border" : ""
                  }`}
                  value={formData.fullName}
                  onChange={handleChange}
                />
                {errors.fullName && (
                  <p className="error-text text-red-400 px-2 mt-1">
                    {errors.fullName}
                  </p>
                )}
                <Spacing lg="20" md="20" />
              </Div>

              <Div className="col-sm-12">
                <label className="cs-primary_color">
                  Services Needed <span className="background-red">*</span>
                </label>
                <ReactSelect
                  isMulti
                  options={services.map((service) => ({
                    value: service.value,
                    label: service.title,
                  }))}
                  onChange={handleServiceChange}
                  value={formData.selectedServices}
                  styles={customStyles}
                  className={`${
                    errors.selectedServices ? "error-border" : ""
                  } z-50`}
                />
                {errors.selectedServices && (
                  <p className="error-text text-red-400 px-2 mt-1">
                    {errors.selectedServices}
                  </p>
                )}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">
                  Mobile <span className="background-red">*</span>
                </label>
                <input
                  type="text"
                  name="mobile"
                  placeholder="9090909090"
                  className={`cs-form_field ${
                    errors.mobile ? "error-border" : ""
                  }`}
                  value={formData.mobile}
                  onChange={handleChange}
                />
                {errors.mobile && (
                  <p className="error-text text-red-400 px-2 mt-1">
                    {errors.mobile}
                  </p>
                )}
                <Spacing lg="20" md="20" />
              </Div>

              <Div className="col-sm-12">
                <button
                  type="submit"
                  className="cs-btn cs-style1"
                  disabled={loading}
                >
                  <span>{loading ? "Sending..." : "Send Message"}</span>
                  {loading && <Icon icon="bi:arrow-right" />}
                </button>
                {errorMessage && (
                  <p className="error-text text-red-400 px-2 mt-1">
                    {errorMessage}
                  </p>
                )}
              </Div>
            </form>
          </Div>
      </Div> 
            {/* End Comment Section */}
          </Div>
          <Div className="col-xl-3 col-lg-4 offset-xl-1">
            {/* Start Sidebar */}
            <Spacing lg="0" md="80" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      {/* Start Blog Details */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
